import { Condition } from "./conditions";

export const comparatorDefs = {
  equals: "is",
  notEquals: "is not",
  contains: "contains",
  notContains: "doesn't contain",
  greaterThan: "is greater than",
  lessThan: "is less than",
  exists: "exists",
  notExists: "doesn't exist"
};

export type Comparator = keyof typeof comparatorDefs;

export type Expression = SerializedCondition[][];

export type SerializedCondition = {
  type: Condition;
  data: string;
};
