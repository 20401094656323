import React, { useState } from "react";
import AIChatbotSettingsTab from "./AIChatbotSettingsTab";

interface Props {
  selectedTab: string;
  aiConfigId: string;
}

export default function AIChatbotSettingsTabs(props: Props) {
  return (
    <div className="flex justify-center border-b-2 border-slate-200 mb-4 top-9 sticky z-50 w-full bg-slate-gray-50">
      <AIChatbotSettingsTab
        title="General"
        route={`/ai/settings/general/${props.aiConfigId}`}
        selected={props.selectedTab === "General"}
      />
      <AIChatbotSettingsTab
        title="Training"
        route={`/ai/settings/training/${props.aiConfigId}`}
        selected={props.selectedTab === "Training"}
      />
      <AIChatbotSettingsTab
        title="Handoff"
        route={`/ai/settings/handoff/${props.aiConfigId}`}
        selected={props.selectedTab === "Handoff"}
      />
      {/* <AIChatbotSettingsTab
        title="Collect Data"
        route={`/ai/settings/data/${props.aiConfigId}`}
        selected={props.selectedTab === "Collect Data"}
      />
      <AIChatbotSettingsTab
        title="Starter Questions"
        route={`/ai/settings/questions/${props.aiConfigId}`}
        selected={props.selectedTab === "Starter Questions"}
      />
      <AIChatbotSettingsTab
        title="Test"
        route={`/ai/settings/test/${props.aiConfigId}`}
        selected={props.selectedTab === "Test"}
      /> */}
    </div>
  );
}
