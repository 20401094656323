import Accordion from "@/components/Accordion";
import LoadingSpinner from "@/components/LoadingSpinner";
import React, { useState } from "react";
import { createPortal } from "react-dom";
import AutomationOverlay from "./AutomationOverlay";
import useAutomations from "@/hooks/useAutomations";

export default function AutomationsEditor() {
  const [showOverlay, setShowOverlay] = useState(false);
  const { automations, fetchAutomations } = useAutomations();

  if (automations === null) {
    return <LoadingSpinner />;
  }

  const rootDiv = document.getElementById("root");
  if (showOverlay && rootDiv) {
    return createPortal(
      <AutomationOverlay
        onClose={() => {
          setShowOverlay(false);
          fetchAutomations();
        }}
      />,
      rootDiv
    );
  }
  return (
    <Accordion.Item eventKey="automations">
      <Accordion.Header>
        <Accordion.Title>Automations</Accordion.Title>
        <Accordion.SubTitle>
          Automations allow you to automate actions based on a path-based flow
          that you create. It's useful for designing a bot that works by
          directing users through menus using buttons. It can also be used to
          create workflows for automated email campaign sending,
        </Accordion.SubTitle>
      </Accordion.Header>
      <Accordion.Body>
        <div className="mb-4">
          <button onClick={() => setShowOverlay(true)} className="btn-link">
            Open Automation editor
          </button>
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
}
