import Accordion from "@/components/Accordion";
import AIConfigurationRow from "./AIConfigurationRow";
import AIConfigurationModal from "./AIConfigurationModal";
import React, { useCallback, useState } from "react";
import useAIConfigs from "@/hooks/useAIConfigs";
import useApi from "@/hooks/useApi";
import { PrimaryBlueButton } from "@velaro/velaro-shared/src/components/Buttons/Buttons";

export default function AIConfigurationEditor() {
  const { aiConfigurations, add, update, remove } = useAIConfigs();
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [scrapedUrls, setScrapedUrls] = useState<string[]>([]);
  const api = useApi();

  const fetchUrls = useCallback(
    async (i: number) => {
      //setLoading(true);
      const json = await api.messaging.get(`AIConfiguration/Urls/${i}`);
      const result = (await json.json()) as string[];

      setScrapedUrls(result);
      //setLoading(false);
    },
    [api.messaging]
  );

  return (
    <>
      <Accordion.Item eventKey="ai">
        <Accordion.Header>
          <Accordion.Title>AI</Accordion.Title>
          <Accordion.SubTitle>Implement an AI chatbot</Accordion.SubTitle>
        </Accordion.Header>
        <Accordion.Body>
          <div className="mb-4">
            <table>
              <thead>
                <tr>
                  <th className="text-left px-4">Config Name</th>
                  <th className="text-left px-4">Status</th>
                </tr>
              </thead>
              <tbody>
                {aiConfigurations.map((aiConfiguration, i) => (
                  <AIConfigurationRow
                    key={i}
                    onSelect={() => {
                      setSelectedIndex(i);
                      fetchUrls(aiConfiguration.id);
                    }}
                    onDelete={() => remove(aiConfiguration, i)}
                    aiConfiguration={aiConfiguration}
                  />
                ))}
              </tbody>
            </table>
          </div>
          <PrimaryBlueButton
            label={"Create new AI"}
            onClick={() => {
              add();
              setSelectedIndex(aiConfigurations.length);
            }}
          />
        </Accordion.Body>
      </Accordion.Item>
      {selectedIndex !== null && (
        <AIConfigurationModal
          onUpdate={(config) => {
            update(config, selectedIndex);
            setSelectedIndex(null);
          }}
          config={aiConfigurations[selectedIndex]}
          scrapedUrls={scrapedUrls}
          onClose={() => setSelectedIndex(null)}
        />
      )}
    </>
  );
}
