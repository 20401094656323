import * as React from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import Logo from "./logo_color.png";
import IconExpand from "@/icons/IconExpand";
import useMenuItems from "@/hooks/useMenuItems";
import { MenuItem } from "@/types";

interface Props {
  children: React.ReactNode;
  headerActions?: React.ReactNode;
}

const subLinkClassName =
  "ml-8 py-1 border-l-2 border-cornflower-blue-400 hover:bg-steel-blue-600 dark:hover:bg-stone-200";

const subLinkActiveClassName =
  "ml-8 py-1 border-l-2 border-cornflower-blue-400 bg-steel-blue-600 dark:bg-stone-200";

const mainLinkActiveClassName =
  "h-9 mb-1 mt-1 rounded w-full text-left flex justify-left items-center hover:bg-steel-blue-600 dark:hover:bg-stone-200 border-l-4 border-cornflower-blue-400 text-cornflower-blue-200";

const navLinkClassName =
  "h-9 mb-1 mt-1 rounded w-full text-left flex justify-left items-center hover:bg-steel-blue-600 dark:hover:bg-stone-200 border-l-4 border-transparent";

function MainLink({
  to,
  label,
  icon,
  onVisit
}: {
  to?: string;
  label: string;
  icon: React.ReactNode;
  onVisit(): void;
}) {
  return (
    <NavLink
      to={to || ""}
      className={({ isActive }) =>
        isActive ? mainLinkActiveClassName : navLinkClassName
      }
      onClick={onVisit}
    >
      <div className="flex flex-col">
        <div className="flex flex-row items-center ml-2">
          {icon}
          <div
            className="flex flex-row items-center pl-4"
            style={{ fontSize: 14 }}
          >
            {label}
          </div>
        </div>
      </div>
    </NavLink>
  );
}

function ExpandableMenu({
  label,
  icon,
  onExpand,
  expanded,
  children,
  visited,
  collapsed,
  to
}: {
  label: string;
  icon: React.ReactNode;
  onExpand(): void;
  expanded: boolean;
  children?: React.ReactNode;
  visited: boolean;
  collapsed: boolean;
  to?: string;
}) {
  function expand() {
    onExpand();
  }

  let iconClassName = "transition fa-solid fa-angle-down";
  let styling = navLinkClassName;

  if (expanded) {
    iconClassName += " fa-rotate-180";
  }

  if (visited) {
    styling = mainLinkActiveClassName;
  }

  if (collapsed) {
    let styling =
      "rounded w-full text-left flex justify-left items-center hover:bg-steel-blue-600 dark:hover:bg-stone-200 border-l-4 border-transparent";
    if (visited) {
      styling =
        "rounded w-full text-left flex justify-left items-center hover:bg-steel-blue-600 dark:hover:bg-stone-200 border-l-4 border-cornflower-blue-400 text-cornflower-blue-400";
    }
    return (
      <div className={styling}>
        <NavLink
          to={to || ""}
          className={({ isActive }) =>
            isActive ? mainLinkActiveClassName : navLinkClassName
          }
          end
        >
          <div className="flex flex-col w-full">
            <div className="flex flex-row items-center ml-1 w-12">{icon}</div>
          </div>
        </NavLink>
      </div>
    );
  }

  return (
    <>
      <div className={styling}>
        <div className="flex flex-col w-full" onClick={() => expand()}>
          <div className="flex flex-row items-center w-full">
            <div className="ml-2">{icon}</div>
            <div
              className="flex flex-row items-center pl-4 w-full"
              style={{ fontSize: 14 }}
            >
              {label}
              <div className="flex flex-row ml-auto pr-4">
                {<i className={iconClassName}></i>}
              </div>
            </div>
          </div>
        </div>
      </div>
      {expanded && <div className="flex flex-col flex-none">{children}</div>}
    </>
  );
}

function SubLink({
  to,
  label,
  onVisit
}: {
  to: string;
  label: string;
  onVisit(): void;
}) {
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        isActive ? subLinkActiveClassName : subLinkClassName
      }
      end
      onClick={onVisit}
    >
      <span className="pl-5" style={{ fontSize: 14 }}>
        {label}
      </span>
    </NavLink>
  );
}

export default function MainLayout(props: Props) {
  const [leftNavExpanded, setLeftNavExpanded] = React.useState(true);
  const [leftNavPinned, setLeftNavPinned] = React.useState(true);
  const [expandedMenus, setExpandedMenus] = React.useState<string[]>([]);
  const [visitedMenu, setVisitedMenu] = React.useState<string>("");
  const location = useLocation();

  const menuItems = useMenuItems();


  const expandMenu = React.useCallback(
    (menuId: string) => {
      const newState = [...expandedMenus];
      const index = newState.indexOf(menuId);
      if (index > -1) {
        delete newState[index];
      } else {
        newState.push(menuId);
      }
      setExpandedMenus(newState);
    },
    [expandedMenus]
  );

  function renderMenu(item: MenuItem, index: number, collapsed: boolean) {
    if (!item.enabled) return;
    if (!item.sublinks && !collapsed) {
      return (
        <div key={index} className="flex flex-col w-full">
          <div className="flex flex-row w-full ">
            <MainLink
              label={item.label}
              to={item.to}
              icon={item.icon}
              onVisit={() => {
                setVisitedMenu(item.label);
                !leftNavPinned ? setLeftNavExpanded(false) : null;
              }}
            />
          </div>
        </div>
      );
    }
    return (
      <ExpandableMenu
        key={index}
        onExpand={() => expandMenu(item.label)}
        label={item.label}
        to={item.to}
        icon={item.icon}
        expanded={expandedMenus.indexOf(item.label) > -1}
        visited={visitedMenu.indexOf(item.label) > -1}
        collapsed={collapsed}
      >
        {leftNavExpanded &&
          item.sublinks &&
          item.sublinks.map((sublink: any, index: number) => {
            if (!sublink.enabled) return;
            return (
              <SubLink
                key={index}
                to={sublink.to}
                label={sublink.label}
                onVisit={() => {
                  setVisitedMenu(item.label);
                  !leftNavPinned ? setLeftNavExpanded(false) : null;
                }}
              ></SubLink>
            );
          })}
      </ExpandableMenu>
    );
  }
  const isSpecificPage = location.pathname === '/workflows/create';
  return (
    <div className="flex flex-col h-screen overflow-hidden">
      {/* Top Nav */}
      {/* <div className="bg-white dark:bg-stone-500 shadow-md flex flex-none items-center z-10 h-16">
        <Link
          to="/"
          className="bg-white dark:bg-stone-500 flex flex-none items-center z-10 h-16"
        >
          <div className="w-20 h-16 flex items-center justify-center bg-white dark:bg-stone-300">
            <img src={Logo} alt="" className="h-9" />
          </div>
          <div className="text-2xl font-semibold text-slate-600 dark:text-white p-4 font-rubik">
            Admin Portal
          </div>
        </Link>
      </div> */}
      {/* Left Nav  Expanded*/}
      <div className="main-layout flex h-screen overflow-hidden">
        {
          <div
            className={`flex flex-col flex-none w-64 px-2 py-4 pb-2 bg-steel-blue-500 text-white dark:bg-stone-400 z-50 left_nav_contrainer ${
              leftNavExpanded ? "menu_active" : null
            }`}
            onMouseLeave={() => {
              !leftNavPinned ? setLeftNavExpanded(false) : null;
            }}
          >
            <div className="overflow-y-auto">
              {menuItems.map((x, i) => renderMenu(x, i, false))}
            </div>
            {/* <div className="mt-auto pt-2 -mx-2 border-t-2 border-cornflower-blue-400 flex flex-row">
              {!leftNavPinned ? (
                <div
                  className="flex me-auto ml-2 p-1 cursor-pointer hover:bg-steel-blue-600 rounded-md"
                  onClick={() => {
                    setLeftNavPinned(!leftNavPinned);
                    setLeftNavExpanded(true);
                  }}
                >
                  <div className="hover:bg-steel-blue-600 rounded-md p-1 relative tooltipsmall">
                    <span className="tooltipsmall-text absolute">
                      Expand and Pin
                    </span>
                    <IconExpand />
                  </div>
                </div>
              ) : (
                <div
                  className="flex ml-auto mr-2 p-1 cursor-pointer hover:bg-steel-blue-600 rounded-md"
                  onClick={() => {
                    setLeftNavPinned(!leftNavPinned);
                    setLeftNavExpanded(false);
                  }}
                >
                  <div className="hover:bg-steel-blue-600 rounded-md p-1 relative tooltipsmall">
                    <span className="tooltipsmall-text absolute">
                      Collapse and Unpin
                    </span>
                    <IconCollapse />
                  </div>
                </div>
              )}
            </div> */}
          </div>
        }
        {/* Left Nav  Collapsed*/}

        <div
          className={`flex flex-col flex-none w-[60px] px-2 py-4 pb-2 bg-steel-blue-500 text-white dark:bg-stone-400 left_nav_contrainer -ml-64 ${
            !leftNavExpanded ? "menu_active" : null
          } ${leftNavPinned ? "menu_pinned" : null}`}
        >
          <div
            className="h-full w-full"
            onMouseEnter={() => setLeftNavExpanded(true)}
          >
            {menuItems.map((x, i) => renderMenu(x, i, true))}
          </div>
          {/* <div className="mt-auto pt-2 -mx-2 border-t-2 border-cornflower-blue-400 flex flex-row">
              <div
                className="flex ml-auto mr-2 p-1 cursor-pointer hover:bg-steel-blue-600 rounded-md"
                onClick={() => setLeftNavPinned(!leftNavPinned)}
              ></div> */}
          {!leftNavPinned && (
            <div className="mt-auto pt-2 -mx-2 border-t-2 border-cornflower-blue-400 flex flex-row">
              <div
                className="flex ml-auto p-1 w-full cursor-pointer justify-center"
                onClick={() => {
                  setLeftNavPinned(!leftNavPinned);
                  setLeftNavExpanded(true);
                }}
              >
                <div className="hover:bg-steel-blue-600 rounded-md p-1 relative tooltipsmall">
                  <span className="tooltipsmall-text absolute">
                    Expand and Pin
                  </span>
                  <IconExpand />
                </div>
              </div>
            </div>
          )}
        </div>
        <main className="flex-auto min-w-0 bg-slate-gray-50 shrink">
          <div
            className={`flex flex-col shrink overflow-auto h-full p-6 ${
              !leftNavPinned ? "main_container" : ""
            } ${leftNavPinned ? "main_container_menu_active" : ""}
              ${isSpecificPage ? 'bg-white' : 'bg-slate-gray-50'}
            `}
          >
            {props.children}
          </div>
        </main>
      </div>
    </div>
  );
}
