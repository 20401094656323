import useAsync from "@/hooks/useAsync";
import FormGroup from "@velaro/velaro-shared/src/components/FormGroup";
import * as React from "react";
import useApi from "@/hooks/useApi";
import LoadingSpinner from "@velaro/velaro-shared/src/components/LoadingSpinner";
import { SiteSettings } from "./types";
import { TimeZone } from "@/types";
import { PrimaryBlueButton } from "@velaro/velaro-shared/src/components/Buttons/Buttons";
import Select from "@/components/Select";

export default function Index() {
  const api = useApi();

  const {
    pending: loadingSiteSettings,
    value: siteSettings,
    setValue: setSiteSettings
  } = useAsync<SiteSettings>({
    immediate: true,
    asyncFunction: async () => {
      const response = await api.messaging.get("SiteSettings");
      return await response.json();
    }
  });

  const { pending: loadingTimezones, value: timeZones } = useAsync<TimeZone[]>({
    immediate: true,
    asyncFunction: async () => {
      const response = await api.messaging.get("Timezones");
      return await response.json();
    }
  });

  async function save() {
    await api.messaging.post("SiteSettings", siteSettings);
  }

  const timeZoneOptions: Record<string, string> = {};
  timeZones?.forEach((tz) => {
    timeZoneOptions[tz.id] = tz.displayName;
  });

  if (loadingSiteSettings || loadingTimezones || !siteSettings) {
    return <LoadingSpinner />;
  }

  return (
    <div className="w-1/2">
      <FormGroup label="Timezone">
        <Select
          options={timeZoneOptions}
          value={siteSettings.timezone}
          onChange={(value) => {
            setSiteSettings(() => {
              return { ...siteSettings, timezone: value };
            });
          }}
        />
      </FormGroup>
      <PrimaryBlueButton label="Save" onClick={save} />
    </div>
  );
}
