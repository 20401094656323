import IconClose from "@/icons/IconClose";
import React from "react";

interface Props {
  onClose: () => void;
}

export default function AutomationOverlay({ onClose }: Props) {
  return (
    <>
      <div className="z-50 fixed left-0 right-0 top-0 bottom-0 bg-slate-900/50"></div>
      <div className="z-50 fixed left-2 right-2 top-2 bottom-2 border-gray-900 border-2 shadow-lg flex flex-col rounded-md">
        <iframe
          className="h-full w-full"
          src={import.meta.env.VITE_AUTOMATION_CLIENT_URL}
        />
      </div>
      <div
        onClick={onClose}
        className="z-50 fixed left-6 top-6 scale-125 bg-white rounded-3xl border border-gray-200 shadow-lg cursor-pointer hover:scale-150"
      >
        <IconClose className="text-crimson-red-600" />
      </div>
    </>
  );
}
