import React, { useState } from "react";
import { Link } from "react-router-dom";

interface Props {
  title: string;
  route: string;
  selected: boolean;
}

export default function AIChatbotSettingsTab(props: Props) {
  const [selected, setSelected] = useState(props.selected);

  let styling = "text-base text-[#64748B] p-2 px-5 font-semibold";

  if (selected) {
    styling =
      "text-base text-[#3383EC] p-2 border-b-2 px-5 border-[#3383EC] font-semibold";
  }
  return (
    <>
      <Link
        to={props.route}
        className={`${styling}`}
        onClick={() => setSelected(true)}
      >
        {props.title}
      </Link>
    </>
  );
}
