import React from "react";
import IconPlus from "@velaro/velaro-shared/src/icons/IconPlus";
import {
  SecondaryBlueButton,
  TeriaryGrayButton
} from "@velaro/velaro-shared/src/components/Buttons/Buttons";
import { ConditionBlock } from "./ConditionBlock";
import { Expression, SerializedCondition } from "./types";

interface Props {
  expression: Expression;
  onUpdate(expression: Expression): void;
}
export function ExpressionEditor({ expression, onUpdate }: Props) {
  function addOrCondition() {
    const clone = expression ? [...expression] : [];
    clone.push([
      {
        type: "",
        data: "{}"
      }
    ]);
    onUpdate(clone);
  }

  function addAndCondition(i: number) {
    const andBlock = expression[i];
    andBlock.push({
      type: "",
      data: "{}"
    });
    onUpdate([...expression]);
  }

  function updateCondition(
    condition: SerializedCondition,
    i: number,
    j: number
  ) {
    const clone = expression ? [...expression] : [];
    clone[i][j] = condition;
    onUpdate(clone);
  }

  function removeCondition(i: number, j: number) {
    const clone = [...expression];
    const andBlock = clone[i];
    andBlock.splice(j, 1);
    if (andBlock.length === 0) {
      clone.splice(i, 1);
    }
    onUpdate(clone);
  }

  function renderAndBlock(conditions: SerializedCondition[], i: number) {
    return (
      <React.Fragment key={i}>
        <div className="rounded-lg bg-slate-gray-100 p-4 flex flex-col">
          <>{conditions.map((c, j) => renderCondition(c, i, j))}</>
          <TeriaryGrayButton
            label="Add Condition"
            prefixIcon={<IconPlus />}
            onClick={() => addAndCondition(i)}
            className="w-full"
          />
        </div>
        <Divider text="OR" />
      </React.Fragment>
    );
  }

  function renderCondition(
    condition: SerializedCondition,
    i: number,
    j: number
  ) {
    return (
      <React.Fragment key={j}>
        <ConditionBlock
          condition={condition}
          onUpdate={(c) => updateCondition(c, i, j)}
          onRemove={() => removeCondition(i, j)}
        />
        <Divider text="AND" />
      </React.Fragment>
    );
  }

  return (
    <>
      {expression && expression.length > 0 && (
        <>{expression.map(renderAndBlock)}</>
      )}
      <SecondaryBlueButton
        label="Add Condition"
        prefixIcon={<IconPlus />}
        onClick={addOrCondition}
        className="w-full"
      />
    </>
  );
}

function Divider({ text }: { text: string }) {
  return (
    <div className="flex items-center gap-2 my-2">
      <div className="grow border border-slate-300"></div>
      <div className="text-center text-slate-500 font-medium">{text}</div>
      <div className="grow border border-slate-300"></div>
    </div>
  );
}
