import { Expression } from "@/components/Expressions/types";
import { UseAIValue } from "./ValueForms/AIRuleForm";
import { UseAutomationValue } from "./ValueForms/AutomationRuleForm";
import { AutoResolveChatValue } from "./ValueForms/AutoResolveChatRuleForm";
import { CreateCrmRecordValue } from "./ValueForms/CreateCrmRecordRuleForm";
import { LanguageConfigValue } from "./ValueForms/LanguageConfigRuleForm";
import { StyleConfigValue } from "./ValueForms/StyleConfigRuleForm";

export type RuleValue =
  | UseAutomationValue
  | UseAIValue
  | CreateCrmRecordValue
  | AutoResolveChatValue
  | LanguageConfigValue
  | StyleConfigValue;

export const triggerDefs = {
  "use-automation": "Use Automation",
  "use-ai": "Use AI",
  "create-crm-record": "Create CRM Record",
  "resolve-chat": "Resolve Chat",
  "determine-language-config": "Determine Language Config",
  "determine-style-config": "Determine Style Config"
};

export type Trigger = keyof typeof triggerDefs;

export interface WorkflowRule {
  // A collection of expressions and a priority for a trigger
  id?: number;
  trigger: Trigger; //spot in the app where a rule needs to run
  priority: number; //order to run rules for a trigger
  expression: Expression;
  value: RuleValue;
}

export const crmDefs = {
  NetSuite: "netsuite",
  SalesForce: "salesforce"
};
export type Crm = keyof typeof crmDefs;

export const crmRecordDefs = {
  Customer: "Customer",
  Account: "Account",
  SupportCase: "Support Case",
  Lead: "Lead",
  Prospect: "Prospect",
  Contact: "Contact",
  Order: "Order"
};
export type CrmRecord = keyof typeof crmRecordDefs;
