import { useEffect, useState } from "react";
import useApi from "./useApi";
import { Tag } from "@/pages/Messaging/Tags/types";

export default function useConversationTags(conversationId?: number) {
  const [all, setAll] = useState<Tag[]>([]);
  const [loading, setLoading] = useState(true);
  //const [selected, setSelected] = useState<Tag[]>([]);
  const api = useApi();
  //const subscribe = useSubscribe();

  useEffect(() => {
    async function fetchAll() {
      const resp = await api.messaging.get("Tags/Conversations");
      setAll(await resp.json());
      setLoading(false);
    }

    fetchAll();
  }, [api.messaging]);

  function handleDelete(id: number) {
    const resp = api.messaging.delete(`Tags/Conversation/Delete/${id}`);
    setAll(all.filter((tag) => tag.id !== id));
  }

  // useEffect(() => {
  //   async function fetchSelected() {
  //     const resp = await api.messaging.get(
  //       `TagAssociation/Conversation/Selected?conversationId=${conversationId}`
  //     );
  //     setSelected(await resp.json());
  //   }

  //   fetchSelected();
  // }, [api.messaging, conversationId]);

  // function save(tags: Tag[]) {
  //   setSelected([...selected, ...tags]);
  //   api.messaging.post("TagAssociation/Conversation/Associate", {
  //     conversationId,
  //     conversationTags: tags
  //   });
  // }

  // function remove(tag: Tag) {
  //   setSelected(selected.filter((t) => t.id !== tag.id));
  //   api.messaging.post("TagAssociation/Conversation/Remove", {
  //     conversationId,
  //     tagId: tag.id
  //   });
  // }

  // useEffect(
  //   () =>
  //     subscribe("conversation-tags-updated", (message) => {
  //       setAll(message.allTags);

  //       if (message.conversationId === conversationId) {
  //         setSelected(message.selectedTags);
  //       }
  //     }),
  //   [conversationId, subscribe]
  // );

  return { all, loading, handleDelete };
}
