import React from "react";
import UserSelectControl from "./UserSelectControl";
import FormGroup from "@velaro/velaro-shared/src/components/FormGroup";

export interface TeamModel {
  name: string;
  userIds: number[];
  autoRoute: boolean;
  autoReassign: boolean;
  routingType: string;
  isDefault: boolean;
  autoUnassign: boolean;
  unassignWarningTime: number;
  unassignTime: number;
}

export const defaultTeamModel: TeamModel = {
  name: "",
  userIds: [],
  autoRoute: false,
  autoReassign: false,
  routingType: "",
  isDefault: false,
  autoUnassign: false,
  unassignWarningTime: 0,
  unassignTime: 0
};

interface Props {
  team: TeamModel;
  onChange(team: Partial<TeamModel>): void;
}

export default function TeamForm({ team, onChange }: Props) {
  return (
    <>
      <FormGroup label="Team Name">
        <input
          type="text"
          required
          value={team.name}
          onChange={(e) => onChange({ name: e.currentTarget.value })}
          className="border"
        />
      </FormGroup>
      <FormGroup label="Users">
        <UserSelectControl
          selectedUserIds={team.userIds}
          onChange={(userIds) => onChange({ userIds })}
        />
      </FormGroup>
      {/* <div className="py-3">
        <label>Default Team</label>
        <input
          id="default"
          type="checkbox"
          className="input ml-2"
          checked={team.isDefault}
          onChange={() => onChange({ ...team, isDefault: !team.isDefault })}
        />
      </div> */}
      <FormGroup label="Auto route chat requests">
        <input
          id="autoRoute"
          type="checkbox"
          className="input"
          checked={team.autoRoute}
          onChange={() => onChange({ ...team, autoRoute: !team.autoRoute })}
        />
      </FormGroup>
      {team.autoRoute && (
        <FormGroup label="Auto Reassign after Unassign">
          <input
            id="autoReassign"
            type="checkbox"
            className="input"
            checked={team.autoReassign}
            onChange={() =>
              onChange({ ...team, autoReassign: !team.autoReassign })
            }
          />
        </FormGroup>
      )}
      {team.autoRoute && (
        <FormGroup label="Routing Type">
          <select
            id="routeType"
            className="input"
            onChange={(e) => onChange({ routingType: e.currentTarget.value })}
            value={team.routingType}
          >
            <option value=""></option>
            <option value="roundRobin">Round Robin</option>
            <option value="leastBusy">Round Robin Least Busy</option>
          </select>
        </FormGroup>
      )}
      <FormGroup label="Auto unassign inactive chats">
        <input
          id="autoUnassign"
          type="checkbox"
          className="input"
          checked={team.autoUnassign}
          onChange={() =>
            onChange({ ...team, autoUnassign: !team.autoUnassign })
          }
        />
      </FormGroup>
      {team.autoUnassign && (
        <div>
          <FormGroup label="Inactive Warning Time">
            <>
              <input
                id="unassignWarningTime"
                type="text"
                className="mx-2 input"
                required
                value={team.unassignWarningTime}
                onChange={(e) =>
                  onChange({
                    unassignWarningTime: Number(e.currentTarget.value)
                  })
                }
              />
              <div className="">Minutes</div>
            </>
          </FormGroup>
          <FormGroup label={"Inactive Unassign Time"}>
            <>
              <input
                id="unassignTime"
                type="text"
                className="mx-2 input"
                required
                value={team.unassignTime}
                onChange={(e) =>
                  onChange({ unassignTime: Number(e.currentTarget.value) })
                }
              />
              <div className="">Minutes</div>
            </>
          </FormGroup>
        </div>
      )}
    </>
  );
}
