import Accordion from "@/components/Accordion";
import * as React from "react";
import AutomationsEditor from "../Bots/AutomationsEditor";
import BotProfilesEditor from "../Bots/BotProfilesEditor";
import AIConfigurationEditor from "../Bots/AI/AIConfigurationEditor";

export default function BotManager() {
  return (
    <>
      <div className="flex pt-10 pl-10 pr-5">
        <div className="flex-1 mr-5">
          <Accordion>
            <AutomationsEditor />
          </Accordion>
          <Accordion>
            <AIConfigurationEditor />
          </Accordion>
          <Accordion>
            <BotProfilesEditor />
          </Accordion>
        </div>
      </div>
    </>
  );
}
