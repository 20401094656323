import Modal from "@velaro/velaro-shared/src/components/Modal";
import useApi from "@/hooks/useApi";
import React, { useMemo, useState } from "react";
import { BotProfile } from "./types";
import LoadingSpinner from "@/components/LoadingSpinner";
import { PrimaryBlueButton } from "@velaro/velaro-shared/src/components/Buttons/Buttons";

interface Props {
  onClose(): void;
  onAdd(profile: BotProfile): void;
}

export default function AddBotProfileModal(props: Props) {
  const [name, setName] = useState("Michelle");
  const [picture, setPicture] = useState<File>();
  const [sending, setSending] = useState(false);
  const [success, setSuccess] = useState<boolean | undefined>(undefined);

  const picturePreviewUrl = useMemo(
    () => (picture ? URL.createObjectURL(picture) : null),
    [picture]
  );
  const api = useApi();

  async function save() {
    setSending(true);
    const formData = new FormData();

    formData.append("name", name);
    if (picture) {
      formData.append("picture", picture);
    }

    const resp = await api.messaging.postFormData("BotProfiles", formData);
    setSuccess(resp.ok);
    if (resp.ok) {
      const result = await resp.json();
      props.onAdd(result);
    } else {
      alert("error");
    }
    setSending(false);
  }

  return (
    <Modal show={true}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          save();
        }}
      >
        <Modal.Header title="Add Bot Profile" onClose={() => props.onClose()} />
        <Modal.Body>
          <div className="p-4">
            <div className="flex mb-4">
              <div className="w-1/3 font-semibold">Name</div>
              <input
                type="text"
                className="input w-2/3"
                value={name}
                max={50}
                onChange={(e) => setName(e.currentTarget.value)}
              />
            </div>
            <div className="flex mb-4">
              <div className="w-1/3 font-semibold">Headshot</div>
              {picturePreviewUrl && (
                <img
                  className="h-24 w-24 rounded-full mb-2"
                  src={picturePreviewUrl}
                  onLoad={() => {
                    URL.revokeObjectURL(picturePreviewUrl!);
                  }}
                />
              )}
              <input
                type="file"
                className="input w-full"
                accept="image/*"
                required
                onChange={(e) => {
                  if (!e.currentTarget.files) {
                    return;
                  }

                  setPicture(e.currentTarget.files[0]);
                }}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="p-4 flex">
            {success === false && (
              <div className="flex flex-row mb-4">
                There was an error saving your changes. Please see Integration
                Log for more details.
              </div>
            )}
            {sending ? (
              <div className="ml-auto flex">
                Saving Changes...
                <LoadingSpinner />
              </div>
            ) : (
              <div className="ml-auto flex">
                <PrimaryBlueButton
                  onClick={() => save()}
                  label="Save Changes"
                />
              </div>
            )}
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
