import { channelDefs, dayOfWeekDefs, SelectOption } from "@/types";
import {
  ChannelCondition,
  ChannelEditor
} from "./ConditionEditors/ChannelEditor";
import { DomainCondition, DomainEditor } from "./ConditionEditors/DomainEditor";
import {
  DayOfWeekCondition,
  DayOfWeekEditor
} from "./ConditionEditors/DayOfWeekEditor";
import {
  TimeOfDayCondition,
  TimeOfDayEditor
} from "./ConditionEditors/TimeOfDayEditor";
import { ConditionProps } from "./ConditionBlock";
import { DateCondition, DateEditor } from "./ConditionEditors/DateEditor";
import { UrlCondition, UrlEditor } from "./ConditionEditors/UrlEditor";
import {
  AttributeCondition,
  AttributeEditor
} from "./ConditionEditors/AttributeEditor";
import { TeamCondition, TeamEditor } from "./ConditionEditors/TeamEditor";
import { comparatorDefs, SerializedCondition } from "./types";

interface ConditionDef {
  label: string;
  editor: (props: ConditionProps) => JSX.Element;
  toLabel: (
    condition: SerializedCondition,
    getTeamNameById: (id: number) => string
  ) => string;
}

export const conditionDefs: Record<string, ConditionDef> = {
  //removing channel for now
  // channel: {
  //   label: "Channel",
  //   editor: ChannelEditor,
  //   toLabel: (condition: SerializedCondition) => {
  //     if (!condition || !condition.data || !condition.type) {
  //       return "condition is incomplete";
  //     }
  //     const channelCondition = JSON.parse(condition.data) as ChannelCondition;
  //     return `channel ${comparatorDefs[channelCondition.comparator]} ${
  //       channelDefs[channelCondition.channel]
  //     }`;
  //   }
  // },
  domain: {
    label: "Domain",
    editor: DomainEditor,
    toLabel: (condition: SerializedCondition) => {
      if (!condition || !condition.data || !condition.type) {
        return "condition is incomplete";
      }
      const domainCondition = JSON.parse(condition.data) as DomainCondition;
      return `domain ${comparatorDefs[domainCondition.comparator]} ${
        domainCondition.domain
      }`;
    }
  },
  url: {
    label: "Url",
    editor: UrlEditor,
    toLabel: (condition: SerializedCondition) => {
      if (!condition || !condition.data || !condition.type) {
        return "condition is incomplete";
      }
      const urlCondition = JSON.parse(condition.data) as UrlCondition;
      return `url ${comparatorDefs[urlCondition.comparator]} ${
        urlCondition.url
      }`;
    }
  },
  dayOfWeek: {
    label: "Day of Week",
    editor: DayOfWeekEditor,
    toLabel: (condition: SerializedCondition) => {
      if (!condition || !condition.data || !condition.type) {
        return "condition is incomplete";
      }
      const dayOfWeekCondition = JSON.parse(
        condition.data
      ) as DayOfWeekCondition;

      let daysText = "";
      dayOfWeekCondition.daysOfWeek.forEach((day, i) => {
        const dayName = dayOfWeekDefs[day];
        if (i === dayOfWeekCondition.daysOfWeek.length - 1) {
          daysText += `or ${dayName}`;
        } else {
          daysText += `${dayName}`;

          if (dayOfWeekCondition.daysOfWeek.length > 2) {
            daysText += ", ";
          } else {
            daysText += " ";
          }
        }
      });

      return `day of week is ${daysText}`;
    }
  },
  timeOfDay: {
    label: "Time of Day",
    editor: TimeOfDayEditor,
    toLabel: (condition: SerializedCondition) => {
      if (!condition || !condition.data || !condition.type) {
        return "condition is incomplete";
      }
      const timeOfDayCondition = JSON.parse(
        condition.data
      ) as TimeOfDayCondition;
      return `time of day is ${timeOfDayCondition.from} to ${timeOfDayCondition.to}`;
    }
  },
  date: {
    label: "Date",
    editor: DateEditor,
    toLabel: (condition: SerializedCondition) => {
      if (!condition || !condition.data || !condition.type) {
        return "condition is incomplete";
      }
      const dateCondition = JSON.parse(condition.data) as DateCondition;
      return `date ${comparatorDefs[dateCondition.comparator]} ${
        dateCondition.date
      }`;
    }
  },
  attribute: {
    label: "Attribute",
    editor: AttributeEditor,
    toLabel: (condition: SerializedCondition) => {
      if (!condition || !condition.data || !condition.type) {
        return "condition is incomplete";
      }
      const attributeCondition = JSON.parse(
        condition.data
      ) as AttributeCondition;

      return `${attributeCondition.attribute} ${
        comparatorDefs[attributeCondition.comparator]
      } ${attributeCondition.value}`;
    }
  },
  team: {
    label: "Team",
    editor: TeamEditor,
    toLabel: (condition: SerializedCondition, getTeamNameById) => {
      if (!condition || !condition.data || !condition.type) {
        return "condition is incomplete";
      }
      const teamCondition = JSON.parse(condition.data) as TeamCondition;

      let teamsText = "";
      teamCondition.teamIds.forEach((teamId, i) => {
        const teamName = getTeamNameById(teamId);
        if (i === teamCondition.teamIds.length - 1) {
          teamsText += `or ${teamName}`;
        } else {
          teamsText += `${teamName}`;
          if (teamCondition.teamIds.length > 2) {
            teamsText += ", ";
          } else {
            teamsText += " ";
          }
        }
      });

      return `team ${comparatorDefs[teamCondition.comparator]} ${teamsText}`;
    }
  }
};

export type Condition = keyof typeof conditionDefs;
export const conditionOptions: SelectOption[] = Object.keys(conditionDefs).map(
  (key: Condition) => {
    return {
      label: conditionDefs[key].label,
      value: key
    };
  }
);
