import useAIConfigs from "@/hooks/useAIConfigs";
import useTeams from "@/hooks/useTeams";
import useWorkflowRules from "@/hooks/useWorkflowConfigs";
import { SecondaryBlueButton } from "@velaro/velaro-shared/src/components/Buttons/Buttons";
import React from "react";
import { RuleValue, triggerDefs, WorkflowRule } from "./types";
import {
  Expression,
  SerializedCondition
} from "@/components/Expressions/types";
import { conditionDefs } from "@/components/Expressions/conditions";

interface Props {
  rule: WorkflowRule;
  editRule(): void;
}

export default function RuleRowItem({ rule, editRule }: Props) {
  const { remove } = useWorkflowRules();
  const { aiConfigurations } = useAIConfigs();
  const { getTeamNameById } = useTeams();
  //this gives us a name instead of the id
  function getRuleValueLabel(key: string, value: RuleValue) {
    const result = value[key as keyof typeof rule.value] as string;
    switch (rule.trigger) {
      case "use-ai":
        return aiConfigurations.find((x) => x.id === Number(result))?.name;
      default:
        return result;
    }
  }

  function getExpressionLabel(expression: Expression) {
    let text = "";
    expression.forEach((andBlock: SerializedCondition[], i) => {
      if (i > 0) {
        text += " OR ";
      }
      andBlock.forEach((condition: SerializedCondition, j) => {
        if (j > 0) {
          text += " AND ";
        }
        const conditionText = conditionDefs[condition.type].toLabel(
          condition,
          getTeamNameById
        );
        text += conditionText;
      });
    });
    return text;
  }
  return (
    <div
      onClick={editRule}
      className="flex flex-row gap-20 items-center border p-4 m-4 bg-slate-50 hover:bg-cornflower-blue-50 hover:border-cornflower-blue-100 cursor-pointer"
    >
      <span className="flex gap-4">
        <span>{triggerDefs[rule.trigger as keyof typeof triggerDefs]}</span>
        <span className="flex flex-col">
          {Object.keys(rule.value).map(
            (k) => `${getRuleValueLabel(k, rule.value)}`
          )}
        </span>
        <span className="font-bold">WHEN</span>
        <span className="flex flex-col">
          {getExpressionLabel(rule.expression)}
        </span>
      </span>
      <span className="flex flex-col">
        <SecondaryBlueButton
          onClick={(e) => {
            e.stopPropagation();
            remove(rule);
          }}
          label="Delete"
        />
      </span>
    </div>
  );
}
