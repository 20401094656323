import React, { useState } from "react";
import { PrimaryBlueButton } from "@velaro/velaro-shared/src/components/Buttons/Buttons";
import AIChatbotSettingsRow from "./AIChatbotSettingsRow";
import { AIConfiguration } from "../types";
import useApi from "@/hooks/useApi";
import useToastAlert from "@/hooks/useToastAlert";
import { Severity } from "@velaro/velaro-shared/src/components/ToastAlert";
import IconTrash from "@velaro/velaro-shared/src/icons/IconTrash";

interface Props {
  aiConfig: AIConfiguration;
}

export default function AIChatbotSettingsTrainingTabWebsite(props: Props) {
  const [trainingUrl, setTrainingUrl] = useState("");
  const [urlList, setUrlList] = useState<string[]>(props.aiConfig.dataUrls);
  const toastAlert = useToastAlert();
  const api = useApi();

  async function addUrl() {
    if (trainingUrl.length === 0) {
      toastAlert.displayToast(Severity.Error, "Please enter a URL");
      return;
    }
    if (urlList.includes(trainingUrl)) {
      toastAlert.displayToast(Severity.Error, "URL already exists in list");
      setTrainingUrl("");
      return;
    }
    const urls = [...urlList];
    urls.push(trainingUrl);
    const config = { ...props.aiConfig, dataUrls: urls };
    const result = await api.messaging.post("AIConfiguration", config);
    if (result.ok) {
      setUrlList(urls);
      setTrainingUrl("");
    } else {
      toastAlert.displayToast(Severity.Error, "Failed to add URL");
    }
  }

  async function deleteUrl(url: string) {
    let urls = [...props.aiConfig.dataUrls];
    urls = urls.filter((u) => u !== url);
    const config = { ...props.aiConfig, dataUrls: urls };
    const result = await api.messaging.post("AIConfiguration", config);
    if (result.ok) {
      setUrlList(urls);
    } else {
      toastAlert.displayToast(Severity.Error, "Failed to delete URL");
    }
  }

  return (
    <>
      <input
        type="text"
        value={trainingUrl}
        onChange={(e) => setTrainingUrl(e.target.value)}
        placeholder="Enter a url"
        className="w-full p-2 border-[1px] rounded-md text-sm mt-4"
      />
      <div className="flex items-center mt-1">
        <input type="checkbox" className="p-2 text-sm" />
        <label className="text-slate-500 text-sm p-2">
          Fetch all links within this page
        </label>
      </div>
      <div className="flex-row flex justify-end gap-4">
        <div className="flex justify-end">
          <PrimaryBlueButton
            label="Add URL"
            onClick={() => addUrl()}
            disabled={trainingUrl.length === 0}
          />
        </div>
        <div className="flex justify-end">
          <PrimaryBlueButton
            label="Train AI"
            disabled={urlList.length === 0}
            onClick={() => console.log("button clicked")}
          />
        </div>
      </div>
      <div>
        <AIChatbotSettingsRow
          title="Urls"
          subtitle="URL's the bot will be trained on"
          input={
            <>
              <div className="ml-2 text-slate-500">
                {urlList.map((url, index) => (
                  <div key={index}>
                    {url}
                    <button onClick={() => deleteUrl(url)}>
                      <IconTrash className="flex ml-2 w-4 h-4 right-0" />
                    </button>
                  </div>
                ))}
              </div>
            </>
          }
        />
      </div>
    </>
  );
}
