import React from "react";
import { Outlet, Route } from "react-router-dom";
import AppLoading from "../components/AppLoading";
import useAuth from "../hooks/useAuth";
import ToastAlertProvider from "@/providers/ToastAlertProvider";
import MainLayout from "@/pages/Shared/MainLayout";
import { AIConfigsProvider } from "@/providers/AIConfigsProvider";
import { AutomationsProvider } from "@/providers/AutomationsProvider";
import { RoleListItemsProvider } from "@/providers/RoleListItemsProvider";
import { WorkflowRulesProvider } from "@/providers/WorkflowRulesProvider";
import { SubscriptionsProvider } from "@/providers/SubscriptionsProvider";
import { SelectedTeamProvider } from "@/providers/SelectedTeamProvider";
import LanguageConfigsProvider from "@/pages/Messaging/WebMessenger/providers/LanguageConfigProvider";
import StyleConfigsProvider from "@/pages/Messaging/WebMessenger/providers/StyleConfigProvider";
import { SkillListItemsProvider } from "@/providers/SkillsProvider";
import { TeamsProvider } from "@/providers/TeamsProvider";
import { UsersProvider } from "@/providers/UsersProvider";

export function PrivateRoute(props: any) {
  const { component, ...rest } = props;
  const auth = useAuth();

  if (auth.isAuthenticated()) {
    return (
      <TeamsProvider>
        <UsersProvider>
          <SkillListItemsProvider>
            <SelectedTeamProvider>
              <RoleListItemsProvider>
                <ToastAlertProvider>
                  <WorkflowRulesProvider>
                    <AutomationsProvider>
                      <AIConfigsProvider>
                        <SubscriptionsProvider>
                          <LanguageConfigsProvider>
                            <StyleConfigsProvider>
                              <MainLayout>
                                <Outlet />
                              </MainLayout>
                            </StyleConfigsProvider>
                          </LanguageConfigsProvider>
                        </SubscriptionsProvider>
                      </AIConfigsProvider>
                    </AutomationsProvider>
                  </WorkflowRulesProvider>
                </ToastAlertProvider>
              </RoleListItemsProvider>
            </SelectedTeamProvider>
          </SkillListItemsProvider>
        </UsersProvider>
      </TeamsProvider>
    );
  } else {
    auth.loginRedirect();
    return <AppLoading />;
  }
}
