import Accordion from "@/components/Accordion";
import useApi from "@/hooks/useApi";
import React, { useCallback, useEffect, useState } from "react";
import AddBotProfileModal from "./AddBotProfileModal";
import { BotProfile } from "./types";

export default function BotProfilesEditor() {
  const api = useApi();
  const [profiles, setProfiles] = useState<BotProfile[]>([]);
  const [showAddProfileModal, setShowAddProfileModal] = useState(false);

  const fetchProfiles = useCallback(async () => {
    const response = await api.messaging.get("BotProfiles/List");
    setProfiles(await response.json());
  }, [api.messaging]);

  const deleteProfile = useCallback(
    async (profile: BotProfile) => {
      await api.messaging.delete("BotProfiles", { id: profile.id });
      const clone = [...profiles];
      clone.splice(profiles.indexOf(profile), 1);
      setProfiles(clone);
    },
    [api.messaging, profiles]
  );

  useEffect(() => {
    fetchProfiles();
  }, [fetchProfiles]);

  return (
    <>
      <Accordion.Item eventKey="botprofiles">
        <Accordion.Header>
          <Accordion.Title>Bot Profiles</Accordion.Title>
          <Accordion.SubTitle>
            Customize the appearance of your bot
          </Accordion.SubTitle>
        </Accordion.Header>
        <Accordion.Body>
          <div className="m-4 flex gap-4">
            {profiles.map((p, i) => (
              <BotProfileCard key={i} profile={p} onDelete={deleteProfile} />
            ))}
            <button
              className="underline"
              onClick={() => setShowAddProfileModal(true)}
            >
              Add profile
            </button>
          </div>
        </Accordion.Body>
      </Accordion.Item>
      {showAddProfileModal && (
        <AddBotProfileModal
          onClose={() => setShowAddProfileModal(false)}
          onAdd={(newProfile) => {
            setProfiles((p) => [...p, newProfile]);
            setShowAddProfileModal(false);
          }}
        />
      )}
    </>
  );
}

function BotProfileCard({
  profile,
  onDelete
}: {
  profile: BotProfile;
  onDelete(p: BotProfile): void;
}) {
  return (
    <div
      style={{ width: "50px" }}
      className="group flex flex-col justify-center items-center"
    >
      <img
        className="h-12 w-12 rounded-full border"
        src={profile.picture}
        alt=""
      ></img>
      <span>{profile.name}</span>
      <span className="invisible group-hover:visible">
        <i
          className="fa fa-trash text-crimson-red-500 cursor-pointer"
          onClick={() => onDelete(profile)}
        />
      </span>
    </div>
  );
}
