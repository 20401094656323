import React from "react";
import { AIConfiguration } from "../types";
import IconTrash1 from "@/icons/IconTrash1";

interface Props {
  aiConfiguration: AIConfiguration;
  onSelect(): void;
  onDelete(): void;
}
export default function AIConfigurationRow({
  aiConfiguration,
  onSelect,
  onDelete
}: Props) {
  return (
    <tr className="hover:bg-cornflower-blue-100">
      <td>
        <button onClick={onSelect} className="btn-link">
          {aiConfiguration.name}
        </button>
      </td>
      <td>
        <div className="px-4">{aiConfiguration.status}</div>
      </td>
      <td>
        <button className="btn-sm" onClick={onDelete}>
          <IconTrash1 />
        </button>
      </td>
    </tr>
  );
}
