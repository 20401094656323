import { Card } from "@/components/Card";
import React from "react";

export default function Home() {
  return (
    <>
      <Card
        to="/users"
        title="Users"
        subtitle="Manage users within your organization."
      />
      <Card
        to=""
        title="Messaging"
        subtitle="Configure Messaging to handle engagements across a number of different channels."
      />
      <Card
        to="/contact_center"
        title="Contact Center"
        subtitle="Configure Contact Center."
      />
    </>
  );
}
