import IconAppsIntegrations from "@/icons/IconAppsIntegrations";
import IconAutomation from "@/icons/IconAutomation";
import IconChannels from "@/icons/IconChannels";
import IconGeneralSettings from "@/icons/IconGeneralSettings";
import IconHome from "@/icons/IconHome";
import IconWorkflows from "@/icons/IconWorkflows";
import { MenuItem } from "@/types";
import React from "react";
import { useMemo } from "react";
import useSubscriptions from "./useSubscriptions";
import IconKnowledgeBase from "@/icons/IconKnowledgeBase";

export default function useMenuItems() {
  const { subscription } = useSubscriptions();

  const menuItems: MenuItem[] = useMemo(() => {
    return [
      {
        label: "Home",
        to: "/",
        icon: <IconHome />,
        enabled: true
      },
      {
        label: "General Settings",
        icon: <IconGeneralSettings />,
        sublinks: [
          {
            label: "Site",
            to: "/site",
            enabled: true
          },
          {
            label: "Users",
            to: "/users",
            enabled: true
          },
          {
            label: "Teams",
            to: "/teams",
            enabled: true
          },
          {
            label: "Skills",
            to: "/skills",
            enabled: true
          },
          {
            label: "Designer",
            to: "/web_messenger",
            enabled: true
          },
          {
            label: "Schedules",
            to: "/work_schedules",
            enabled: true
          },
          {
            label: "Surveys",
            to: "/surveys",
            enabled: true
          },
          {
            label: "Deployments",
            to: "/deployment",
            enabled: true
          },
          {
            label: "Tags",
            to: "/tags",
            enabled: true
          },
          {
            label: "Canned Responses",
            to: "/canned_responses",
            enabled: true
          },
          {
            label: "File Storage",
            to: "/files",
            enabled: true
          }
        ],
        enabled: true
      },
      {
        label: "Workflows",
        icon: <IconWorkflows />,
        enabled: true,
        sublinks: [
          {
            label: "Create",
            to: "/workflows/create",
            enabled: true
          },
          {
            label: "My Workflows",
            to: "/workflows",
            enabled: true
          }
        ]
      },
      {
        label: "Bots and Automation",
        to: "/bots",
        icon: <IconAutomation />,
        enabled: subscription?.enableAutomation
      },
      {
        label: "AI Chatbot",
        to: "/ai",
        icon: <IconAutomation />,
        enabled: subscription?.enableAI
      },
      {
        label: "Workflow Rules",
        to: "/rules",
        icon: <IconWorkflows />,
        enabled: subscription?.enableWorkflowRules
      },
      {
        label: "Channels",
        to: "/channels",
        icon: <IconChannels />,
        sublinks: [
          {
            label: "Facebook",
            to: "/facebook",
            enabled: subscription?.enableFacebook
          },
          {
            label: "SMS",
            to: "/sms",
            enabled: subscription?.enableSms
          },
          {
            label: "WhatsApp",
            to: "/whatsapp",
            enabled: subscription?.enableWhatsapp
          },
          {
            label: "IVR",
            to: "/ivr",
            enabled: subscription?.enableIvr
          }
        ],
        enabled: true
      },
      {
        label: "Knowledge Base Editor",
        to: "/kb",
        icon: <IconKnowledgeBase />,
        sublinks: [
          {
            label: "Settings",
            to: "/kb/settings",
            enabled: true
          },
          {
            label: "Topics",
            to: "/kb/topics",
            enabled: true
          },
          {
            label: "Articles",
            to: "/kb/articles",
            enabled: true
          },
          {
            label: "Site Map",
            to: "/kb/map",
            enabled: true
          }
        ],
        enabled: true
      },
      {
        label: "Integrations",
        to: "/integrations",
        icon: <IconAppsIntegrations />,
        sublinks: [
          {
            label: "Integrations",
            to: "/integrations",
            enabled: true
          },
          {
            label: "Log",
            to: "/integrations/log",
            enabled: true
          }
        ],
        enabled: true
      }
    ];
  }, [subscription]);

  return menuItems;
}
